<template>
  <div>
    <MobileHeader v-if="isMobile"/>
    <BeeHeader v-else/>
    <LayoutArticle :class="isMobile ? 'mobile-view' : 'py-10'">
      <div class="max-w-prose mx-auto" :class="isMobile ? 'text-xs p-3' : 'text-lg'">
        <h1 class="mt-2 block text-center leading-8 font-extrabold tracking-tight text-gray-900" :class="isMobile ? 'text-3xl' : 'text-xl'">
          {{ title }}
        </h1>
        <article id="1a0ce177-0543-4e2e-9b51-c64173cd396a" class="page sans">
          <div class="page-body">
            <h3 id="0340474a-5cfb-4877-865f-ebadf7648527" class="">1. THÔNG TIN VỀ ĐƠN VỊ</h3>
            <p id="afe5eb55-0836-48a8-a7d3-4bbfbf407504" class="">a) Tên và địa chỉ trụ sở của thương nhân, tổ chức hoặc tên và địa chỉ thường trú của cá nhân:</p>
            <ul id="f865cce0-caea-4c44-a5d2-66f33f09f677" class="bulleted-list">
              <li>CÔNG TY CỔ PHẦN KHOA HỌC DỮ LIỆU</li>
            </ul>
            <ul id="cd09397d-8b32-420d-8daf-3eb4b3ff723d" class="bulleted-list">
              <li>Địa chỉ trụ sở: Tầng 6, Tòa nhà AZ Lâm Viên, 107A Nguyễn Phong Sắc, P. Dịch Vọng, Q. Cầu Giấy, Tp. Hà Nội, Việt Nam.</li>
            </ul>
            <p id="08ca6a97-4c0e-44ee-bd5a-d6bd97993cd8" class="">b) Số điện thoại, địa chỉ thư điện tử hoặc một phương thức liên hệ trực tuyến khác:</p>
            <ul id="5832f895-3eba-4000-9913-da32e2d0e108" class="bulleted-list">
              <li>Số điện thoại: 033.806.2221</li>
            </ul>
            <ul id="3c58f5c1-89dc-4307-b5ee-94621d1aaee8" class="bulleted-list">
              <li>Hỗ trợ: metric@ecomviet.vn</li>
            </ul>
            <h3 id="6e2958a2-2a62-452a-b51e-5abcf940c699" class="">2. ĐỊNH NGHĨA</h3>
            <p id="32e1459e-3e3d-4b66-9e21-2c70af848c52" class="">“Metric Ecom Việt” – Công ty Cổ phần Khoa học Dữ liệu</p>
            <p id="cfb0d8a1-9e0a-4738-b9a4-47441213964b" class="">“Dịch vụ”, “Sản phẩm”, hoặc “Sản phẩm/Dịch vụ” – là toàn bộ và bất kỳ một sản phẩm, dịch vụ, ứng dụng, phần mềm... nào mà Metric Ecom Việt cung cấp trên bất kỳ các kênh nào.</p>
            <p id="9f8ca7c8-7c4b-45a2-8053-7f788b0306bc" class="">“Website” – Trang thông tin điện tử khi truy cập địa chỉ market.beecost.vn</p>
            <p id="d5f79168-291a-414d-9a8a-67457fa96d3b" class="">“Nội dung” – Hình ảnh, biểu tượng, bài viết, video được đăng trên Website, Sản phẩm/Dịch vụ.</p>
            <p id="21ef244a-3f4b-4083-b3c6-225ebad3f647" class="">“Chủ tài khoản” – Người đăng ký ban đầu; hoặc người giữ tài khoản đăng ký trên Sản phẩm/Dịch vụ.</p>
            <p id="a2ece9b6-ba9d-4350-af33-ce5056cbea78" class="">“Người dùng” – Là Người đăng ký, tạo tài khoản, sử dụng các Sản phẩm/Dịch vụ của Metric Ecom Việt. Bao gồm và không giới hạn cá nhân, tổ chức, đại diện/nhân viên của tổ chức, ...</p>
            <p id="2784d9b5-6375-44a3-8325-3388a3dd0cbe" class="">"Bên thứ ba" - Khách hàng, đối tác, nhà cung cấp của cửa hàng của Người dùng hoặc của Metric Ecom Việt.</p>
            <p id="ffc95d74-4c20-494c-aa5e-863cd90d17af" class="">“Tính năng” – Tính năng hiện có và đang được cung cấp trên Metric Ecom Việt</p>
            <h3 id="87cf1ef4-6437-49fd-a729-d4f5e13df30d" class="">3. PHẠM VI ÁP DỤNG</h3>
            <p id="8187e680-423d-4f4c-9dbd-b85e10c464dd" class="">Điều khoản sử dụng này áp dụng cho việc sử dụng các Sản phẩm/Dịch vụ của Metric Ecom Việt. Metric Ecom Việt có thể thay đổi, điều chỉnh Điều khoản sử dụng này và công khai trên hệ thống ngay khi được thông qua, Người dùng có thể xem những thông tin mới cập nhật vào bất cứ lúc nào tại Website, Sản phẩm/Dịch vụ của Metric Ecom Việt. Nếu Người dùng tiếp tục sử dụng Dịch vụ có nghĩa là Người dùng chấp nhận và chắc chắn đồng ý tuân theo Điều khoản sử dụng mới nhất được cập nhật.</p>
            <p id="e5a3c726-1025-4764-85fb-ffb0c752ff58" class="">Bất cứ sự vi phạm nào của Người dùng đối với các điều khoản và điều kiện này đều có thể dẫn đến việc bị đình chỉ hoặc kết thúc tài khoản của Người dùng, Dịch vụ hoặc những hoạt động được phép khác theo Điều khoản sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt.</p>
            <h3 id="914e8d81-4e48-410a-aade-14274e5a9382" class="">4. ĐIỀU KIỆN GIAO DỊCH CHUNG</h3>
            <p id="6646f2c7-0123-4c54-abbf-c86fb638febb" class="">Việc Người dùng lựa chọn sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt đồng nghĩa với việc Người dùng chấp thuận tuân thủ các điều khoản, chính sách, thỏa thuận sử dụng dịch vụ đã được công bố trên Website hoặc Sản phẩm/Dịch vụ của Metric Ecom Việt. Ngoài ra, khi sử dụng các Dịch vụ cụ thể của Metric Ecom Việt, Người dùng phải tuân theo các điều khoản và điều kiện riêng áp dụng cho từng Dịch vụ đó theo từng thời điểm.</p>
            <p id="787f4e0a-49b8-432e-b5b3-b670525499e7" class="">Trường hợp có bất kỳ điều khoản nào của Điều khoản sử dụng này hết hiệu lực hoặc không thể thi hành vì bất cứ lý do gì sẽ chỉ ảnh hưởng đến điều, khoản đã xác định hết hiệu lực đó và không ảnh hưởng đến hiệu lực của các điều khoản còn lại. Nếu có sự khác biệt giữa Điều khoản sử dụng này và các Thỏa thuận sử dụng Dịch vụ khác của Metric Ecom Việt thì quy định nào mới nhất sẽ có hiệu lực.</p>
            <p id="7cf88eed-b950-408f-91fe-cb65cfb0ff07" class="">Người dùng tự đảm bảo rằng thiết bị ở trong tình trạng kết nối mạng khi cần truy cập dữ liệu kinh doanh mới nhất.</p>
            <p id="1db0c8f3-6750-4f00-bbed-1f2c43397725" class="">Người dùng phải tuân thủ các điều khoản của Apple App Store và Google Play cũng như các nền tảng mà Metric Ecom Việt cung cấp Sản phẩm/Dịch vụ, bao gồm cả các quy tắc sử dụng trên các nền tảng đó.</p>
            <h3 id="2dcc46b7-42ca-4a66-b2f7-a97147cfc374" class="">5. SỬ DỤNG HỢP PHÁP</h3>
            <p id="d4ec31c5-9595-40c6-87bf-0945481a0219" class="">Metric Ecom Việt cung cấp phần mềm/ứng dụng với các tính năng hỗ trợ nghiên cứu thị trường, quản lý bán hàng cho Người dùng, vì vậy Metric Ecom Việt không can thiệp và không chịu trách nhiệm đối với bất kỳ nội dung hoạt động marketing/bán hàng của Người dùng như: Khách hàng của Người dùng, hàng hóa, chất lượng hàng hóa mà Người dùng đang kinh doanh…cũng như Metric Ecom Việt không chịu trách nhiệm đối với các khiếu kiện nào từ Người dùng hoặc Bên thứ ba phản ánh về nội dung hoạt động bán hàng của Người dùng.</p>
            <p id="6b21ffa6-2b16-4488-bd8d-5abb2352b131" class="">Người dùng phải nhận thức và phải chấp nhận rằng Người dùng có trách nhiệm sử dụng Dịch vụ của Metric Ecom Việt vào công việc kinh doanh hàng hóa, dịch vụ trong khuôn khổ cho phép của pháp luật hiện hành và thuần phong mỹ tục của Việt Nam. Người dùng không được sử dụng dịch vụ của Metric Ecom Việt để thực hiện các hành động có thể gây ảnh hưởng đến quyền và lợi ích hợp pháp của của Metric Ecom Việt, cộng đồng hay quyền lợi của bất kì cơ quan, tổ chức, cá nhân nào, ví dụ như: Tuyên truyền nội dung đồi trụy, chống phá nhà nước, phát tán thư rác và hoặc gửi các thông tin không mong muốn đến những tổ chức và cá nhân khác trong hệ thống dưới mọi hình thức, kinh doanh hàng hóa thuộc danh mục hàng hóa cấm kinh doanh....</p>
            <p id="1b424ef4-59ad-4c17-87ad-611683bd1121" class="">Metric Ecom Việt có toàn quyền tạm ngưng cung cấp hoặc ngăn chặn quyền tiếp tục truy cập ứng dụng của Người dùng vào hệ thống dịch vụ của Metric Ecom Việt khi có căn cứ hoặc có dấu hiệu nghi ngờ Người dùng có dấu hiệu vi phạm pháp luật, có báo cáo, khiếu nại từ Bên thứ ba gửi về Metric Ecom Việt phản ánh tiêu cực về hoạt động kinh doanh của Người dùng hoặc bất kì hoạt động nào của Người dùng khi sử dụng Sản phẩm/Dịch vụ mà Metric Ecom Việt nhận thấy có khả năng gây ảnh hưởng đến sự an toàn của Metric Ecom Việt, quyền, lợi ích hợp pháp của cộng đồng, cơ quan, tổ chức, cá nhân khác.</p>
            <p id="5ab41148-07be-4a62-bb0e-7423d767bcbb" class="">Khi đó, để tiếp tục sử dụng Sản phẩm/Dịch vụ, Metric Ecom Việt có quyền yêu cầu Người dùng cung cấp thông tin để xác minh và/hoặc thực hiện cam kết để có thể tiếp tục sử dụng dịch vụ. Trường hợp nhận thấy sự việc có tính chất nghiêm trọng, Metric Ecom Việt có toàn quyền nhờ đến sự can thiệp của các cơ quan nhà nước có thẩm quyền, các đơn vị có chức năng chuyên môn để đảm bảo quyền và lợi ích hợp pháp cho Metric Ecom Việt cũng như cộng đồng.</p>
            <h3 id="30a51909-6596-4fd7-80bd-791ddb99ff82" class="">6. TẠM NGỪNG, CHẤM DỨT CUNG CẤP DỊCH VỤ</h3>
            <p id="a62aa27c-d3b0-4964-91ec-7331c94a46e8" class="">Metric Ecom Việt có quyền tạm ngừng hoặc chấm dứt hoàn toàn việc cung cấp dịch vụ mà không hoàn lại bất kỳ một chi phí nào cho Người dùng trong các trường hợp sau:</p>
            <p id="b66b096d-bf9c-4508-ae74-f481376a0cc3" class="">a) Người dùng sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt vào bất kỳ mục đích/hình thức nào vi phạm pháp luật Việt Nam, đặc biệt về vấn đề bản quyền phần mềm, ca khúc…</p>
            <p id="3323401a-2e26-4977-aa0e-591a27b8572d" class="">b) Người dùng gửi, tạo liên kết hoặc trung chuyển cho các dữ liệu mang tính chất bất hợp pháp, đe doạ, lừa dối, thù hằn, xuyên tạc, nói xấu, tục tĩu, khiêu dâm, xúc phạm… hay các hình thức bị pháp luật Việt Nam ngăn cấm khác dưới bất kỳ cách thức nào.</p>
            <p id="51602774-311e-40dc-a151-c2cc9b3dd17f" class="">c) Người dùng lưu trữ, truyền bá các dữ liệu nào mà cấu thành hoặc khuyến khích các hình thức phạm tội; hoặc các dữ liệu mang tính vi phạm luật sở hữu trí tuệ (sáng chế, nhãn hiệu, quyền thiết kế, bản quyền hay bất kỳ quyền…) hoặc xâm phạm các quyền lợi của bất kỳ cá nhân nào.</p>
            <p id="20d78306-1eb2-40d9-ae15-cf321fb023da" class="">d) Người dùng sử dụng Sản phẩm/Dịch vụ cho mục đích phá hoại.</p>
            <p id="0a3f2372-783e-422e-86f5-6846a496ff1c" class="">đ) Người dùng sử dụng các chương trình có khả năng làm tắc nghẽn hoặc đình trệ hệ thống, như gây cạn kiệt tài nguyên hệ thống, làm quá tải bộ vi xử lý và bộ nhớ.</p>
            <p id="7f6f279f-0049-4ef0-a8ff-9df3bbab5800" class="">e) Người dùng sử dụng website hoặc tài khoản của mình để xâm nhập trái phép vào website khác hoặc gây ảnh hưởng đến Người dùng khác của Metric Ecom Việt.</p>
            <p id="d695c8ea-b4a1-426f-8b37-7d3d905d45d6" class="">f) Người dùng bị tấn công bởi bên thứ ba, làm ảnh hưởng nghiêm trọng đến hệ thống của Metric Ecom Việt. Trường hợp bị tấn công Metric Ecom Việt sẽ tạm ngừng website của Người dùng trong hệ thống, khoanh vùng xử lý và sẽ đưa vào hoạt động bình thường sau khi khắc phục sự cố.</p>
            <p id="419fc45e-f8a9-4876-9d21-7f6968ec0ff9" class="">g) Người dùng có những vi phạm khác mà các cơ quan nhà nước có thẩm quyền yêu cầu đóng website.</p>
            <p id="68281b05-9d85-46fc-aace-2b7646d4be84" class="">h) Người dùng không thanh toán các chi phí đúng hạn.</p>
            <p id="ca5765fd-aec4-40dc-8e28-a4978530b796" class="">i) Người dùng có hành vi làm ảnh hưởng đến uy tín và thương hiệu của Metric Ecom Việt trên các phương tiện thông tin đại chúng.</p>
            <p id="cd6a6346-6a78-4a59-9502-763c0cbac4fa" class="">k) Trường hợp nêu tại Mục 5 của bản Điều khoản sử dụng này về Sử dụng Hợp pháp.</p>
            <p id="77367998-7d76-47c9-a03a-9c5978c2ca26" class="">l) Trường hợp khác mà Pháp luật có quy định.</p>
            <h3 id="ad8fc5a8-dd4b-46da-b8b2-ac5a452224b2" class="">7. SỬ DỤNG KHU VỰC CHUNG VÀ KHU VỰC RIÊNG</h3>
            <p id="bf4be9df-f04b-4501-8f88-0733d2156418" class="">a) Sử dụng khu vực chung:</p>
            <p id="866fb714-4160-40c6-b0aa-f6f0d3869cd9" class="">Người dùng hiểu và chấp nhận rằng có quyền quản lý, khai thác và sử dụng Khu vực chung trên Sản phẩm/Dịch vụ của Metric Ecom Việt theo quyết định và xem xét duy nhất của Metric Ecom Việt, mọi yêu cầu của Người dùng về sử dụng Khu vực chung trên Sản phẩm/Dịch vụ của Metric Ecom Việt cho mục đích riêng của Người dùng có thể được tính phí ngoài phí dịch vụ đã thông báo ban đầu.</p>
            <p id="ec3586a7-3d96-4e69-84aa-a9a7d8558791" class="">b) Sử dụng khu vực riêng</p>
            <p id="2cc204e8-b8dd-421a-9701-0301198cd425" class="">Người dùng có toàn quyền sử dụng Khu vực riêng được bảo vệ bằng mật khẩu cho các hoạt động của mình. Metric Ecom Việt không can thiệp và không chịu trách nhiệm về các thao tác của Người dùng tác động lên dữ liệu và thông tin trên Khu vực riêng. Mọi yêu cầu phát sinh trong việc sử dụng Khu vực riêng không nằm trong cam kết ban đầu có thể được tính phí.</p>
            <p id="bc1cc5c2-3633-4b66-864b-7ab07424c1cc" class="">c) Truy cập khu vực riêng</p>
            <p id="4d026668-2de4-4409-a2d3-ac0c0d5f9848" class="">Người dùng hiểu và chấp nhận rằng Metric Ecom Việt có quyền truy cập khu vực riêng của Người dùng khi ở một trong các trường hợp sau:</p>
            <ul id="05ef2759-d0e8-4a48-a501-cc03b1d10e3a" class="bulleted-list">
              <li>Được Người dùng đồng ý</li>
            </ul>
            <ul id="bc82ddab-4a94-4411-a447-39ba9a44a2a7" class="bulleted-list">
              <li>Người dùng yêu cầu nhằm mục đích hỗ trợ sử dụng, xử lý sự cố</li>
            </ul>
            <ul id="e09f8ffe-4f3a-4742-9a07-7aec1497c7c9" class="bulleted-list">
              <li>Khi có yêu cầu của cơ quan nhà nước, Tòa án… theo quy định của pháp luật</li>
            </ul>
            <ul id="ab32eabd-b6ce-4b46-aea8-2f531cb7774c" class="bulleted-list">
              <li>Trong tình huống khẩn cấp, nhằm ngăn chặn xâm nhập trái phép hoặc tấn công phá hoại từ bên ngoài.</li>
            </ul>
            <p id="583a21d1-cefa-4e80-a894-fc2de89af866" class="">Người dùng hiểu và chấp nhận rằng Người dùng không có quyền yêu cầu truy cập khu vực riêng của Metric Ecom Việt, khu vực riêng của Người dùng khác, khu vực quản trị Dịch vụ, Hệ thống và Quản trị website, ứng dụng.</p>
            <h3 id="3c11db3e-0d05-4b8b-8bd2-0a9117354560" class="">8. QUYỀN SỞ HỮU TRÍ TUỆ</h3>
            <p id="5c36fa2a-9d76-4f57-b96a-db57dd3c1578" class="">Tất cả các Sản phẩm/Dịch vụ của Metric Ecom Việt thuộc bản quyền của Công ty Cổ phần Khoa học Dữ liệu.</p>
            <p id="ef424d93-168a-45cf-8b18-b9d4abff3d90" class="">Do đó, tất cả Nội dung trên Sản phẩm/Dịch vụ của Metric Ecom Việt đều thuộc sở hữu của Metric Ecom Việt. Metric Ecom Việt cho phép Người dùng xem, tải về và in những nội dung sau:</p>
            <ul id="45846f53-e42f-40bf-9e69-7f69ad1f0c7e" class="bulleted-list">
              <li>Nội dung đã được Metric Ecom Việt công khai trên Sản phẩm/Dịch vụ.</li>
            </ul>
            <ul id="b4de23b8-54a3-4720-8237-4340450ce5ac" class="bulleted-list">
              <li>Nội dung các thông tin thuộc sở hữu của chính Người dùng</li>
            </ul>
            <p id="16d8dc89-2d3f-4b61-af10-2afecf4bad7a" class="">Khi sử dụng các thông tin tại Sản phẩm/Dịch vụ, Người dùng cần trích dẫn nguồn từ Website theo đúng quy định, chỉ sử dụng vào các mục đích phi lợi nhuận.</p>
            <p id="15c31846-e69a-497e-8302-7eabb360824d" class="">Ngoại trừ việc cho phép như trên, Người dùng không được phép sao chép, điều chỉnh hoặc sử dụng lại Nội dung mà không có sự cho phép trước đó bằng văn bản của Metric Ecom Việt. Người dùng có thể liên hệ địa chỉ: metric@ecomviet.vn để được hỗ trợ khi có nhu cầu sử dụng các Nội dung trên. Trong trường hợp được chấp thuận bởi Metric Ecom Việt, Người dùng phải bảo đảm là việc Người dùng sử dụng thông tin có được từ Sản phẩm/Dịch vụ không vi phạm quyền, lợi ích của các cá nhân/tổ chức khác và phải ghi rõ nguồn từ market.beecost.vn.</p>
            <h3 id="cf8e9467-d61f-4411-90cc-a38f9a6bb52a" class="">9. CHÍNH SÁCH BẢO MẬT THÔNG TIN CÁ NHÂN NGƯỜI DÙNG</h3>
            <p id="723a2937-3b7e-4b65-96ad-3cf2a210fa7d" class="">Vui lòng xem Chính sách bảo mật của Metric Ecom Việt tại địa chỉ:</p>
            <p id="65782c89-5481-4abd-a061-ecc86f34f536" class=""><a href="https://market.beecost.vn/privacy">https://market.beecost.vn/privacy</a></p>
            <h3 id="bb799035-6e92-440d-9ded-352b814394cd" class="">10. DỊCH VỤ LIÊN KẾT CỦA BÊN THỨ BA</h3>
            <p id="9a71079b-0c37-4b02-98d5-942a04f8c28d" class="">Sản phẩm/Dịch vụ của https://market.beecost.vn/privacy đã/đang và sẽ không ngừng mở rộng tính ưu việt của ứng dụng, không loại trừ việc liên kết với các đơn vị cung cấp dịch vụ của Bên thứ ba (các sàn thương mại điện tử, đơn vị vận chuyển…)</p>
            <p id="fd07a702-4cb9-4be2-b63f-1f991dadf172" class="">Khi Người dùng sử dụng các dịch vụ liên kết với Bên thứ ba được tích hợp trên Sản phẩm/Dịch vụ... có nghĩa là Người dùng đã hiểu và đồng ý tuân thủ các điều khoản và điều kiện chung, riêng áp dụng cho từng dịch vụ. Việc sử dụng dịch vụ liên kết này sẽ phụ thuộc vào từng chính sách của Bên thứ ba tại từng thời điểm. Khi dịch vụ liên kết của Bên thứ ba xảy ra các sự cố như bị gián đoạn, bị chấm dứt; Metric Ecom Việt sẽ cố gắng, nỗ lực tối đa để hỗ trợ Người dùng có trải nghiệm tốt nhất nhưng không đồng nghĩa là Metric Ecom Việt cam kết chịu trách nhiệm với Người dùng về những sự cố đã xảy ra của dịch vụ liên kết này.</p>
            <p id="5e9d5ec9-06cb-4fa6-a7f3-0c7fadbcb124" class="">Khi sử dụng Dịch vụ liên kết với Bên thứ ba, Người dùng hiểu và đồng ý các điều khoản sử dụng của Dịch vụ liên kết và đồng ý chia sẻ các thông tin và dữ liệu cần thiết để sử dụng Dịch vụ liên kết. Sản phẩm/Dịch vụ của Metric Ecom Việt chỉ chia sẻ các thông tin và dữ liệu đã được Người dùng đồng ý chia sẻ và cần thiết để Bên thứ ba có thể cung cấp Dịch vụ liên kết. Người dùng được khuyến khích tham khảo thêm các điều khoản sử dụng của Dịch vụ liên kết với Bên thứ ba về các thông tin và dữ liệu được chia sẻ khi sử dụng Dịch vụ liên kết.</p>
            <h3 id="bb76792f-ab5c-4804-b578-6918ab139d37" class="">11. QUY ĐỊNH PHƯƠNG THỨC THANH TOÁN VÀ HOÀN TRẢ</h3>
            <p id="5d792157-5e7f-46b6-bebf-4d86ba012e14" class="">Khi Người dùng quyết định sử dụng dịch vụ của chúng tôi, Người dùng có thể lựa chọn một hình thức thanh toán phù hợp nhất trong 4 hình thức dưới đây để thanh toán một cách đơn giản và thuận tiện.</p>
            <ul id="5bb92c9b-bb09-4fb0-b7a4-386c1edda153" class="bulleted-list">
              <li>Hình thức 1: Thu phí tận nơi</li>
            </ul>
            <p id="85929aa3-88a8-4e07-906c-ccabf796a3c0" class="">Nhân viên Metric Ecom Việt sẽ liên lạc và hẹn bạn thời gian để thu phí tận nơi khi chúng tôi nhận được yêu cầu đăng ký dịch vụ của Người dùng. Hình thức này áp dụng trong phạm vi nội thành Hà nội và nội thành thành phố Hồ Chí Minh.</p>
            <p id="7264e629-7a51-45b4-aa47-a486cca8748b" class="">Thời gian cung cấp dịch vụ: Trong vòng 24h làm việc sau khi thanh toán</p>
            <ul id="40768164-3d6f-4678-9c4a-1dfddb48d9ae" class="bulleted-list">
              <li>Hình thức 2: Thanh toán qua chuyển khoản Ngân hàng hoặc Internet Banking</li>
            </ul>
            <p id="2472fb95-346e-492f-8d4d-dccdfd89d9be" class="">Người dùng có thể đến bất kì Phòng giao dịch ngân hàng, ATM hoặc sử dụng tính năng Internet Banking để chuyển tiền vào một trong các tài khoản sau:</p>
            <p id="064781b8-5800-4647-9c3f-bfde64a91be4" class="">1. VPBANK – Ngân hàng TMCP Việt Nam Thịnh Vượng</p>
            <p id="b7e434b4-20f1-4d46-bc72-378bd9bed9de" class="">Tên tài khoản: Công ty Cổ phần Khoa học Dữ liệu</p>
            <p id="c8018950-089d-4781-9464-e04858871b6d" class="">Số tài khoản: 180945587</p>
            <p id="3305dfc5-10e3-4481-bb9f-24d82f23cd59" class="">Chi nhánh: Thăng Long</p>
            <ul id="d8eaff96-dff8-47c4-9435-1a53d8d08066" class="bulleted-list">
              <li>Hình thức 3: Văn phòng Công ty Cổ phần Khoa học Dữ liệu</li>
            </ul>
            <p id="df4d55c4-c6b3-431e-a70d-6660e1a4706c" class="">Địa chỉ trụ sở: Tầng 6, Tòa nhà AZ Lâm Viên, 107A Nguyễn Phong Sắc, P. Dịch Vọng, Q. Cầu Giấy, Tp. Hà Nội, Việt Nam.</p>
            <p id="bdeec168-a4db-434d-b1e7-74306bb10a7a" class="">Số điện thoại: +(84)-989-091-287Giờ làm việc: 8h - 17h30 hàng ngày từ Thứ 2 - Thứ 6. Riêng thứ 7 từ 8h - 12h Thời gian cung cấp dịch vụ: Ngay lập tức</p>
            <ul id="763c5952-ad23-4976-b8be-98f720a8ad04" class="bulleted-list">
              <li>Hình thức 4: Cổng Thanh toán - Người dùng thực hiện thanh toán ngay trên Sản phẩm/Dịch vụ của Metric Ecom Việt, theo giao diện, quy định của Metric Ecom Việt và Cổng thanh toán mà Metric Ecom Việt sử dụng.</li>
            </ul>
            <p id="a8c9fa01-5283-4e20-8ebe-ade09c8d35f5" class=""><strong>Chính sách hoàn trả:</strong> Metric Ecom Việt không hỗ trợ chính sách hoàn trả cho các khoản thanh toán mà Người dùng đã thực hiện, trừ khi giữa Người dùng và Metric Ecom Việt có các điều khoản/thỏa thuận riêng và đặc thù.</p>
            <p id="0305d1d4-17d2-4898-b5c1-dbd0411f2efe" class="">Các câu hỏi về vấn đề thanh toán, hoàn trả, Người dùng liên hệ tổng đài hỗ trợ khách hàng: 033.806.2221 - Email: metric@ecomviet.vn.</p>
            <h3 id="6841c078-d18a-4546-b9a3-51a39cdd0fea" class="">12. XỬ LÝ SỰ CỐ</h3>
            <p id="ae0e77ba-ba2a-4385-8a1b-6025f1e79d8a" class="">Người dùng có trách nhiệm thông báo ngay Metric Ecom Việt khi phát hiện sự cố, tích cực phối hợp với Metric Ecom Việt để Metric Ecom Việt có thể khắc phục sớm nhất vấn đề cho Người dùng. Trong trường hợp sự cố liên quan đến Bên thứ ba, Người dùng có trách nhiệm phối hợp với những bên liên quan để giải quyết. Người dùng hiểu và chấp nhận rằng, trong mọi trường hợp, Metric Ecom Việt luôn cố gắng hỗ trợ và khắc phục, tuy nhiên Metric Ecom Việt sẽ không chịu trách nhiệm trước những thiệt hại phát sinh do sự chậm trễ không thông báo hoặc che giấu thông tin sự cố của Người dùng.</p>
            <h3 id="521d2664-21db-47c3-8ef5-a33ee04f24a9" class="">13. GIỚI HẠN TRÁCH NHIỆM</h3>
            <p id="3fc15a17-0bd6-4ec6-b570-b2c4aba34881" class="">Sau khi bàn giao các thông số quản trị dịch vụ cho người dùng, Metric Ecom Việt không chịu trách nhiệm và không bảo đảm về tính chính xác về những thông tin của Người dùng trên phần mềm, website, ứng dụng của Người dùng. Đồng thời, Metric Ecom Việt không chịu trách nhiệm pháp lý và bồi thường cho Người dùng và bên thứ ba đối với các thiệt hại trực tiếp, gián tiếp, vô ý, vô hình, các thiệt hại về lợi nhuận, doanh thu, uy tín phát sinh từ việc sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt sau khi Metric Ecom Việt đã bàn giao cho Người dùng.</p>
            <p id="ba574485-0cca-4ae8-9d2f-9f3c514cd5ae" class="">Metric Ecom Việt chắc chắn sẽ không chịu bất kỳ trách nhiệm hoặc trách nhiệm liên đới nào đối với hậu quả của việc truy cập trái phép đến Sản phẩm/Dịch vụ; trang thiết bị và dữ liệu của Người dùng hoặc dữ liệu cá nhân của Người dùng vì tai nạn, phương tiện bất hợp pháp, thiết bị của Bên thứ ba và các nguyên nhân khác nằm ngoài sự kiểm soát của Metric Ecom Việt.</p>
            <p id="a11c0d11-7a8b-4985-bc13-09ff538e6722" class="">Như một điều kiện khi sử dụng Sản phẩm/Dịch vụ, Người dùng đồng ý rằng Metric Ecom Việt, nhân viên, các tổ chức thành viên, cổ đông, đại lý, nhà cung cấp của Metric Ecom Việt sẽ không chịu trách nhiệm với Bên thứ ba nào về các thiệt hại về lợi nhuận, cơ hội kinh doanh hoặc thiệt hại, chi phí phát sinh trực tiếp hay gián tiếp vì kết nối với Sản phẩm/Dịch vụ của Metric Ecom Việt.</p>
            <p id="5a5389e9-bc4c-48b0-9920-50f025fd92eb" class="">Metric Ecom Việt cũng sẽ không chịu bất kỳ trách nhiệm nào hoặc trách nhiệm liên đới đối với chất lượng sản phẩm, dịch vụ, thông tin của các Bên thứ ba có liên kết với Sản phẩm/Dịch vụ của Metric Ecom Việt. Ngoài ra, Người dùng cũng hiểu rằng, Metric Ecom Việt không có trách nhiệm và thẩm quyền xác nhận, chứng nhận thông tin và chất lượng dịch vụ, sản phẩm hay bồi thường các thiệt hại liên quan đến việc Người dùng có sử dụng dịch vụ, sản phẩm được quảng cáo trên Website/ Ứng dụng của Bên thứ ba. Metric Ecom Việt khuyến nghị Người dùng nên tìm hiểu đối tác thật kỹ càng trước khi xúc tiến hợp tác để tránh các thiệt hại không mong muốn xảy ra. Metric Ecom Việt không bảo đảm cũng như không chịu trách nhiệm về kết quả kinh doanh của Người dùng sau khi sử dụng dịch vụ.</p>
            <h3 id="ecbdf027-c4d7-409f-9538-e6a51b69e78f" class="">14. ĐẢM BẢO CUNG CẤP DỊCH VỤ</h3>
            <p id="1b1c044d-c18a-467f-9cdd-09bd5cfcf852" class="">Metric Ecom Việt sẽ không bảo đảm rằng Ứng dụng của chúng tôi sẽ luôn luôn sẵn sàng, luôn có thể sử dụng, không bao giờ bị gián đoạn, đúng thời gian, không có lỗi bởi các sự cố như: Hacker, mất mạng Internet trên diện rộng…. Tuy nhiên, Metric Ecom Việt và các nhân viên của Metric Ecom Việt sẽ cam kết cố gắng trong mọi điều kiện và làm hết sức khả năng có thể để đảm bảo rằng Sản phẩm/Dịch vụ của Metric Ecom Việt luôn được sẵn sàng cho việc sử dụng. Metric Ecom Việt cam kết nỗ lực khắc phục sự gián đoạn và đưa ra sự điều chỉnh, sửa chữa và hỗ trợ trong khả năng có thể để phục hồi hệ thống nhanh chóng.</p>
            <h3 id="eeb30613-1baf-4426-9e67-e03f60716ed8" class="">15. TÀI LIỆU HƯỚNG DẪN SỬ DỤNG</h3>
            <p id="82d1ec61-2e2a-4af4-b9a7-5815b1c29172" class="">Người dùng có thể truy cập và tra cứu tài liệu hướng dẫn ngay trang Hướng dẫn sử dụng của Metric Ecom Việt. Tuy nhiên, Metric Ecom Việt có thể sẽ không cung cấp cho Người dùng bất kỳ hướng dẫn sử dụng nào dưới dạng văn bản in ấn. Tài liệu hướng dẫn sử dụng sẽ được Metric Ecom Việt gửi kèm với email xác nhận tài khoản ngay sau khi Người dùng đăng ký tài khoản thành công.</p>
            <h3 id="9e832de8-691e-4d32-bbe9-5f90bf76813e" class="">16. GIẢI QUYẾT THẮC MẮC, KHIẾU NẠI, TRANH CHẤP</h3>
            <p id="325e133e-380d-430e-9a6b-8660b9c235bc" class="">a) Phương thức tiếp nhận thắc mắc, khiếu nại</p>
            <p id="7f6df632-26ce-4504-a847-7256da8ef74d" class="">Mọi trường hợp đóng góp ý, thắc mắc, khiếu nại, Người dùng vui lòng nào gửi về Metric Ecom Việt theo các phương thức sau:</p>
            <ul id="a78e93f4-54c8-4195-b9de-131c3732afa5" class="bulleted-list">
              <li>Gửi email tới địa chỉ: metric@ecomviet.vn</li>
            </ul>
            <ul id="503f646f-21f3-41ef-8dab-518da3bcdfe5" class="bulleted-list">
              <li>Tổng đài tư vấn và hỗ trợ: 033.806.2221</li>
            </ul>
            <ul id="7f1dc13c-44d3-46c6-bd70-595f17ce48e2" class="bulleted-list">
              <li>Hoặc liên hệ trực tiếp:CÔNG TY CỔ PHẦN KHOA HỌC DỮ LIỆUĐịa chỉ trụ sở: Tầng 6, Tòa nhà AZ Lâm Viên, 107A Nguyễn Phong Sắc, P. Dịch Vọng, Q. Cầu Giấy, Tp. Hà Nội, Việt Nam.Số điện thoại: +(84)-989-091-287</li>
            </ul>
            <p id="ba01db7a-9e75-4655-8d17-24961dbad989" class="">Tùy thuộc vào tính chất phức tạp của nội dung khiếu nại, Metric Ecom Việt sẽ có thời hạn xử lý tương ứng. Kết quả giải quyết khiếu nại sẽ được thông báo tới Người dùng, trường hợp cần thiết, Metric Ecom Việt có thể mời người khiếu nại tới làm việc trực tiếp. Metric Ecom Việt sẽ nỗ lực để luôn giải quyết các khiếu nại của Người dùng trong thời gian sớm nhất và trên tinh thần thương lượng, hòa giải, tôn trọng, hai bên cùng có lợi.</p>
            <p id="ad59f333-1f43-49d9-af61-2f1e94ff9513" class="">b) Trong quá trình sử dụng nếu xảy ra tranh chấp giữa người sử dụng và Metric Ecom Việt, hai bên sẽ tiến hành đàm phán hòa giải với tinh thần hữu nghị. Trong trường hợp không giải quyết được bằng hòa giải sẽ đưa ra Toà án kinh tế Hà Nội giải quyết.</p>
            <h3 id="def41847-6ecd-45a0-8acf-eb2b18c4eff6" class="">17. Các điều khoản/chính sách liên quan.</h3>
            <p id="02ed514d-4843-4fd8-ac93-b197e8eafe37" class="">Nếu Người dùng sử dụng các Sản phẩm/Dịch vụ khác trong hệ sinh thái của BeeCost, chính sách này sẽ bao gồm cả <a href="https://beecost.vn/privacy">chính sách bảo mật của BeeCost</a> cũng như <a href="https://beecost.vn/term">điều khoản sử dụng của BeeCost</a>.</p>
          </div>
        </article>
      </div>
    </LayoutArticle>
    <BeeFooter/>
  </div>
</template>
<script>

import LayoutArticle from "@/layout/LayoutArticle";
import BeeHeader from "@/components/partial/header/BeeHeader";
import BeeFooter from "@/components/partial/footer/BeeFooter";
import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
import MobileHeader from "@/components/partial/header/MobileHeader";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "TermsView",
  components: {
    BeeHeader,
    BeeFooter,
    LayoutArticle,
    MobileHeader
  },
  mixins: [
    mixinStoreGetterGlobal
  ],
  data() {
    {
      let title = 'Điều khoản sử dụng Metric Ecom Việt'
      return {
        title,
        MESSENGER_ID
      }
    }
  },
  head() {
    return {
      title: this.title,
    }
  }
}

</script>

<style lang="scss" scoped>
.p-content {
  @apply text-lg text-gray-600 leading-8;
}
</style>
